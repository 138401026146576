jQuery( document ).ready( function( $ ) {
	setTimeout(function(){
		$(function(){
			$('.testimonials-slider').bxSlider( 
				{
					speed: 1000,		
		    		mode: 'fade',   
		    		auto: true,
		    		pager: false,
		    		touchEnabled: false,
					autoHover: true,
					adaptiveHeight: true		
				}
			);
			$('.case-studies-slider').bxSlider( 
				{
					speed: 1000,	
		    		mode: 'fade',   
		    		auto: false,
		    		pager: false,
		    		touchEnabled: false, // make link clickable
					autoHover: true		
				}
			);	
			$('.posts-slider').bxSlider( 
				{
					// nextText: '<',
	    //     		prevText: '>',
	    			speed: 1000,	
		    		mode: 'fade',   
		    		auto: true,
		    		pager: false,
		    		touchEnabled: false,
					autoHover: true		
				}
			);

		});
	}, 1000 ); // delay for a second to ensure it renders out the data
	//$('.case-study-before-link').matchHeight();
	$.fn.matchHeight._update();

	$('.case-study-link').on( 'click', function(){
		
	});
	/** https://html-online.com/articles/simple-popup-box/ **/
	
    $(".trigger_popup_fricc").on('click', function(){
       $('.hover_bkgr_fricc').show();
    });
    $('.hover_bkgr_fricc').on('click', function(){
       $('.hover_bkgr_fricc').hide();
    });
    $('.popup-content').on('click', function( e ){
     e.stopPropagation();
 	});
    $('.popupCloseButton').on('click', function(){
        $('.hover_bkgr_fricc').hide();
    });
	
} );
